import React, { FC, useState, useEffect, useMemo, Fragment } from 'react';
import clsx, { ClassValue } from 'clsx';
import { useLazyQuery } from '@apollo/client';
import { fullName, photoPlaceholder } from 'utils/helpers';
import { IMenuTypes, INotifications } from 'utils/models';
import query from 'graphql/query';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';

// components
import MenuAction from 'components/MenuAction';
import PopOver from 'components/PopOver';
import Notifications from 'components/notifications/Notifications';
import { SvgPollnBrand, SvgDownArrow } from 'components/SvgIcons';

interface IHeader {
  className?: ClassValue;
  showNotification?: boolean;
  menu: IMenuTypes[];
}

const Header: FC<IHeader> = ({ className, showNotification = false, menu }) => {
  const { user, isAdmin } = useAuth();
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState<boolean>(false);

  const [doctorNotifications, { data }] = useLazyQuery<{
    doctorNotifications: {
      code: string;
      success: boolean;
      message: string;
      notifications: INotifications[];
    };
  }>(query.doctorNotifications, {
    fetchPolicy: 'network-only'
  });
  const notifications = data?.doctorNotifications?.notifications;
  const notificationsWithDefault = useMemo(() => notifications || [], [notifications]);

  useEffect(() => {
    if (notificationsWithDefault.length > 0) {
      const unReadNotification = notificationsWithDefault.find(({ is_read }) => !is_read);
      setHasUnreadNotifications((unReadNotification && 'is_read' in unReadNotification) || false);
    }
  }, [notificationsWithDefault]);

  useEffect(() => {
    doctorNotifications();
  }, [doctorNotifications]);

  return (
    <div className={clsx(className, 'relative w-full', isAdmin && 'bg-red-300')}>
      <div className="p-1 mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center space-y-0.5 text-left space-x-6">
            <Link to="/dashboard">
              <SvgPollnBrand className="text-polln-solid-green-600 w-14 h-5 ml-2" />
              {/* <div className="text-gray-500 font-bold text-sm">Doctor Dashboard</div> */}
            </Link>
            <div className="h-5 border-l border"></div>
            <div className="text-xs uppercase tracking-widest text-[#132420]">Doctor Dashboard</div>
          </div>

          {isAdmin && (
            <div className="flex justify-center p-2 uppercase text-xl font-bold">
              You are logged-in as admin
            </div>
          )}

          <div className="flex justify-end items-center">
            <div className="hidden md:inline-block">
              <div className="flex items-center space-x-5">
                {/* <div
                  className={clsx(
                    'bg-no-repeat bg-cover bg-center',
                    'rounded-full h-12 w-12 border'
                  )}
                  style={{
                    backgroundImage: `url(${
                      user?.profile?.profile_pic || photoPlaceholder['doctor']
                    })`
                  }}
                /> */}

                {user?.profile?.first_name && user?.profile?.last_name && (
                  <div className="text-right">
                    <div className="text-black text-polln-black-600 font-Erode-Variable font-medium text-base">
                      {fullName(user)}
                    </div>
                    <div className="text-black text-polln-black-600 font-normal font-Erode-Variable -mt-1 text-xs">
                      Polln Doctor
                    </div>
                  </div>
                )}

                {showNotification && (
                  <div className="relative">
                    <PopOver
                      placement="bottom-end"
                      offSetX={40}
                      offSetY={10}
                      classWidth="sm:w-6/12 2xl:w-4/12"
                      btnToggle={
                        <Fragment>
                          {/* <SvgEnvelope className="w-6 h-6 cursor-pointer" />
                          <div
                            className={clsx(
                              'w-3 h-3 ',
                              'absolute -top-1 -right-1',
                              'w-3 h-3 ',
                              'absolute -top-1 -right-1',
                              'rounded-full',
                              hasUnreadNotifications
                                ? 'bg-polln-orange-100 animate-ping'
                                : 'bg-transparent'
                            )}
                          /> */}
                        </Fragment>
                      }
                    >
                      <h2 className="text-xl font-extrabold mb-2">Messages</h2>
                      <Notifications setHasUnreadNotifications={setHasUnreadNotifications} />
                    </PopOver>
                  </div>
                )}
              </div>
            </div>

            <div className="hidden md:block">
              <MenuAction
                menuButton={
                  <div className="flex items-center">
                    <div className="relative">
                      <img
                        src={
                          user?.profile?.profile_pic
                            ? `${user?.profile?.profile_pic}&not-from-cache-please`
                            : photoPlaceholder['doctor']
                        }
                        alt={fullName(user)}
                        className={clsx('object-contain rounded-md', 'h-9 w-9 mt-2')}
                        crossOrigin="anonymous"
                      />
                      <div
                        className={clsx(
                          'w-3 h-3 ',
                          'absolute top-1 -right-1',
                          'w-3 h-3 ',
                          'absolute top-1 -right-1',
                          'rounded-full',
                          hasUnreadNotifications ? 'bg-[#C2DA49]' : 'bg-transparent'
                        )}
                      />
                    </div>
                    <div className="ml-3 mt-2">
                      {' '}
                      <SvgDownArrow />
                    </div>
                  </div>
                }
                classNameMain="mr-3"
                menu={menu}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
