import { saveAs } from 'file-saver';
import {
  format,
  differenceInYears,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday
} from 'date-fns';
// import * as Sentry from '@sentry/browser';
import { TCalendarView, TGenders } from 'utils/types';
import { GENDER_OPTIONS } from 'utils/constants';
import {
  ICreateSchedule,
  IDoctorDateSchedule,
  IDoctorDetails,
  IFileCheckResponse,
  IMedicineDetails,
  IPatientData,
  IPatientDetails,
  IPatientHistory,
  ISelectOptions
} from 'utils/models';
import PhotoPlaceholderDoctor from 'assets/images/placeholder-doctor.png';
import PhotoPlaceholderPatient from 'assets/images/placeholder-upload.jpg';
import { isPlainObject } from 'is-plain-object';
import { CONF_API_URL } from './config';

const genders: any = {
  MALE: 'Male',
  FEMALE: 'Female',
  NOTTOSAY: 'Preferred not to say'
};

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const ensureMediaPermissionsWithDetails = async (
  name: string,
  consultationId: string | undefined,
  referenceId: string | undefined
) => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            // Sentry.captureMessage(
            //   `Doctor ${name} denied camera and audio permission for consultation ID: ${consultationId} with reference ID: ${referenceId}, ERROR: ${error}`
            // );

            return error;
          });
      }
    });
};

export const ensureMediaPermissions = async () => {
  return await navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((error) => {
            // Sentry.captureMessage(`Doctor denied camera and audio permission. ERROR: ${error}`);

            return error;
          });
      }
    });
};

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export const range = (start: number, end: number) =>
  Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

export const countDown = (timer: number) => {
  const totalSeconds = (timer %= 3600);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return {
    minutes,
    seconds
  };
};

export const fileToObjectUrl = (image: File | string) =>
  image instanceof File ? URL.createObjectURL(image) : image;

export const getGender = (gender: TGenders, genderOther: string) => {
  const genderLabel = GENDER_OPTIONS.find((item: ISelectOptions) => item.value === gender);
  return gender === 'OTHERS' ? genderOther : genderLabel?.label;
};

export const getGenderValue = (gender: TGenders | undefined, type: 'select' | 'input') => {
  let genderValue: any = gender
    ? !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY')
      ? 'OTHERS'
      : gender
    : '';

  if (type === 'input') {
    genderValue =
      gender && !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY') ? gender : '';
  }

  return {
    value: genderValue,
    label: gender
      ? !(gender === 'FEMALE' || gender === 'MALE' || gender === 'NOTTOSAY')
        ? gender
        : genders[gender]
      : ''
  };
};

export const getDobAge = (dateOfBirth: Date) => {
  const dob = format(new Date(dateOfBirth), 'dd/MM/yyy');
  const age = differenceInYears(new Date(), new Date(dateOfBirth));

  return {
    dob,
    age,
    dobAge: `${dob} - ${age} years old`
  };
};

export const getConditions = (user: IPatientDetails | null) => {
  let conditions = '-';

  if (user) {
    let arrConditions: string[] = [];
    if (user.profile.conditions) {
      arrConditions = JSON.parse(user.profile.conditions);
    }

    if (user.profile.other_conditions) arrConditions.push(user.profile.other_conditions);
    conditions = arrConditions.join(', ');
  }

  return conditions;
};

export const getMedicalHistory = (history: string, others: string) => {
  let medicalHistory = '-';

  if (history || others) {
    let arrHistory: string[] = [];
    if (history) {
      arrHistory = JSON.parse(history);
    }

    if (others) arrHistory.push(others);
    medicalHistory = arrHistory.join(', ');
  }

  return medicalHistory;
};

export const getProfilePicInput = (profilePic: string | null | undefined) => {
  let profilePicUrl = profilePic;

  if (profilePic && profilePic.includes('https://')) {
    const split = profilePic.split('/');
    const splitSource = split[4].split('?');
    profilePicUrl = `${split[3]}/${splitSource[0]}`;
  }

  return profilePicUrl;
};

export const fileChecker = ({
  file,
  fileTypes = [],
  fileSizeLimit
}: {
  file: File;
  fileTypes: string[];
  fileSizeLimit: number;
}) => {
  let response: IFileCheckResponse = {
    success: true,
    message: ''
  };

  if (file) {
    // @ts-ignore
    const ext = file.name.split('.').pop().toLowerCase();
    const validFileTypes = fileTypes;

    if (validFileTypes.includes(ext)) {
      const sizeLimit = fileSizeLimit / 1024;
      const sizeLimitByte = fileSizeLimit * 1000;
      const decimals = '';
      const k = 1024;
      const dm: number = decimals || 2;
      const sizes: string[] = ['Bytes', 'KB', 'MB'];
      const bigSizes: any = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(file.size) / Math.log(k));
      const iC = Math.floor(Math.log(sizeLimitByte) / Math.log(k));
      const fileSize = parseFloat((file.size / Math.pow(k, i)).toFixed(dm));

      if ((fileSize > sizeLimit && sizes[i] === 'MB') || bigSizes.includes(sizes[i], bigSizes)) {
        const fSize = sizes[i];
        const cSize = sizes[iC];
        const limit = iC > 1 ? Math.round(sizeLimit) : fileSizeLimit;
        response = {
          success: false,
          message: `${file.name} has ${fileSize}${fSize} of file size. Please limit file size to ${limit}${cSize} and under only.`
        };
      }
    } else {
      response = {
        success: false,
        message: `${file.name} is not a valid file. We only accept ${validFileTypes.join(', ')}.`
      };
    }
  }

  return response;
};

export const checkValidNumber = (input: any) => input.match(/^[0-9]+$/);

export const getRangeDates = (start: Date, end: Date) => {
  const date = new Date(start.getTime());
  const dates = [];

  while (date <= end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const groupBy = (array: any[], f: any) => {
  // eslint-disable-next-line no-sequences
  return array.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};

export const photoPlaceholder: {
  patient: string;
  doctor: string;
} = {
  patient: PhotoPlaceholderPatient,
  doctor: PhotoPlaceholderDoctor
};

export const fullName = (user: IPatientDetails | IDoctorDetails | IPatientData | null) =>
  user ? `${user?.profile?.first_name || '-'} ${user?.profile?.last_name || '-'}` : '-';

export const calendarDate = ({
  date,
  view,
  dateFormat = 'dd, MMMM yyyy'
}: {
  date: Date;
  view?: TCalendarView;
  dateFormat?: string;
}) => {
  let formatDate = format(date, dateFormat);
  let from = format(date, 'yyyy-MM-dd 00:00:00');
  let to = format(date, 'yyyy-MM-dd 23:59:59');

  if (view === 'dayGridMonth') {
    formatDate = `${format(startOfMonth(date), dateFormat)} - ${format(
      endOfMonth(date),
      dateFormat
    )}`;

    from = format(startOfMonth(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfMonth(date), 'yyyy-MM-dd 23:59:59');
  }

  if (view === 'timeGridWeek') {
    formatDate = `${format(startOfWeek(date, { weekStartsOn: 1 }), dateFormat)} - ${format(
      endOfWeek(date, { weekStartsOn: 1 }),
      dateFormat
    )}`;

    from = format(startOfWeek(date), 'yyyy-MM-dd 00:00:00');
    to = format(endOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59');
  }

  return {
    formatDate,
    fromUtc: new Date(from).toISOString(),
    from,
    to,
    toUtc: new Date(to).toISOString()
  };
};

export const getAddress = (user: IPatientDetails | IDoctorDetails | null) =>
  user?.profile?.street && user?.profile?.state && user?.profile?.city && user?.profile?.postcode
    ? `${user?.profile?.street || '-'} ${user?.profile?.city || '-'}, ${
        user?.profile?.state || '-'
      }, ${user?.profile?.postcode || '-'}`
    : '';

export const downloadFile = (fileName: string, fileKey: string) =>
  saveAs(`${CONF_API_URL}/download?key=${fileKey}`, fileName);

export const formatId = (value: number, size: number) => {
  let s = value + '';
  while (s.length < size) s = '0' + s;
  return s;
};

export const checkBooleanString = (value?: string) => ({
  stringValue: value ? (JSON.parse(value) ? 'Yes' : 'No') : '-',
  isTrue: value ? JSON.parse(value) : false
});

export const checkBoolean = (value?: 1 | 2) => ({
  stringValue: value ? (value === 1 ? 'Yes' : 'No') : '-',
  isTrue: value ? (value === 1 ? true : false) : false
});

export const getPreExistingConditions = (healthHistory?: IPatientHistory) => ({
  lungCondition: healthHistory?.heart_lung_condition
    ? (JSON.parse(healthHistory.heart_lung_condition) as string[])
    : ([] as string[]),
  neuroCondition: healthHistory?.mental_neuro_condition
    ? (JSON.parse(healthHistory.mental_neuro_condition) as string[])
    : ([] as string[]),
  muscleCondition: healthHistory?.muscle_condition
    ? (JSON.parse(healthHistory.muscle_condition) as string[])
    : ([] as string[]),
  otherCondition: healthHistory?.other_condition
    ? (JSON.parse(healthHistory.other_condition) as string[])
    : ([] as string[]),
  otherConditionList: healthHistory?.other_condition_list
    ? (JSON.parse(healthHistory.other_condition_list) as string[])
    : ([] as string[])
});

export const getMedicineDetails = (healthHistory?: IPatientHistory) => ({
  withPrescription: healthHistory?.medicine_with_prescription_details
    ? `Frequency: ${
        (JSON.parse(healthHistory.medicine_with_prescription_details) as IMedicineDetails).frequency
      }, Type: ${
        (JSON.parse(healthHistory.medicine_with_prescription_details) as IMedicineDetails).type
      }`
    : '-',
  withoutPrescription: healthHistory?.medicine_without_prescription_details
    ? `Frequency: ${
        (JSON.parse(healthHistory.medicine_without_prescription_details) as IMedicineDetails)
          .frequency
      }, Type: ${
        (JSON.parse(healthHistory.medicine_without_prescription_details) as IMedicineDetails).type
      }`
    : '-'
});

export const getHolisticHealth = (healthHistory?: IPatientHistory) => ({
  diet: healthHistory?.current_diet ? healthHistory?.current_diet : '-',
  activityLevel: healthHistory?.physical_activity_level
    ? healthHistory?.physical_activity_level
    : '-',
  emotions: healthHistory?.stress_emotions ? healthHistory?.stress_emotions : '-'
});

export const getAdditionalInformation = (patient?: IPatientDetails) => ({
  achieve: patient?.profile?.achieve_polln ? patient?.profile?.achieve_polln : '-',
  share: patient?.profile?.addtl_info ? patient?.profile?.addtl_info : '-',
  heard: patient?.profile?.about_polln ? patient?.profile?.about_polln : '-'
});

export const getNextSchedules = (createSchedule: ICreateSchedule): [IDoctorDateSchedule?] => {
  let schedules: [IDoctorDateSchedule?] = [];
  for (let day of createSchedule.days) {
    let schedule = null;
    switch (day) {
      case 'MON':
        schedule = {
          start_at: nextMonday(createSchedule.start_at),
          end_at: nextMonday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'TUE':
        schedule = {
          start_at: nextTuesday(createSchedule.start_at),
          end_at: nextTuesday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'WED':
        schedule = {
          start_at: nextWednesday(createSchedule.start_at),
          end_at: nextWednesday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'THU':
        schedule = {
          start_at: nextThursday(createSchedule.start_at),
          end_at: nextThursday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'FRI':
        schedule = {
          start_at: nextFriday(createSchedule.start_at),
          end_at: nextFriday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'SAT':
        schedule = {
          start_at: nextSaturday(createSchedule.start_at),
          end_at: nextSaturday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      case 'SUN':
        schedule = {
          start_at: nextSunday(createSchedule.start_at),
          end_at: nextSunday(createSchedule.end_at),
          recurring_id: createSchedule.recurring_id
        };
        break;
      default:
        break;
    }
    if (schedule) {
      schedules.push(schedule);
    }
  }
  return schedules;
};

export const handleDownloadLink = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  // link.setAttribute('download');

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  if (link.parentNode) link.parentNode.removeChild(link);
};
