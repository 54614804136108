import React, { FC, Fragment, ReactNode, useRef, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { IMenuTypes, isIMenuAtag, isIMenuButton, isIMenuLink, isMenuActions } from 'utils/models';
import { TMenuButtonVariant } from 'utils/types';
import { Portal } from 'react-portal';
import { Placement } from '@popperjs/core/index';
import { usePopper } from 'react-popper';

// components
import Button from './Button';

interface IMenuAction {
  menuButton: string | ReactNode;
  menuButtonVariant?: TMenuButtonVariant;
  menu: IMenuTypes[];
  placement?: Placement;
  classNameMain?: ClassValue;
  classNameMenuButton?: ClassValue;
}

const rounded = {
  none: '',
  sm: 'rounded-sm',
  rounded: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full'
};

const menuButtonVariants = {
  none: '',
  rounded: clsx(
    'hover:bg-gray-100',
    'rounded-full p-2 border border-transparent',
    'active:border-gray-400'
  )
};

const MenuAction: FC<IMenuAction> = ({
  menuButton,
  menuButtonVariant = 'none',
  placement = 'bottom-end',
  classNameMain,
  classNameMenuButton,
  menu
}) => {
  const popperElRef = useRef(null);

  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 10] }
      }
    ]
  });

  return (
    <Menu as="div" className={clsx('relative inline-block text-left', classNameMain)}>
      {/** @ts-ignore */}
      <div ref={setTargetElement}>
        <Menu.Button
          as="div"
          className={clsx(
            'text-white uppercase font-bold h-full cursor-pointer',
            menuButtonVariants[menuButtonVariant],
            classNameMenuButton
          )}
        >
          {menuButton}
        </Menu.Button>
      </div>

      <Portal>
        <div ref={popperElRef} style={{ ...styles.popper, zIndex: 99 }} {...attributes.popper}>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Menu.Items
              className={clsx(
                // 'w-max py-1 z-[9999] bg-white',
                'w-56 py-1 z-[9999] bg-white',
                'divide-y divide-gray-100',
                'mt-2 origin-top-right bg-white rounded-md shadow-lg',
                'ring-1 ring-black ring-opacity-5 focus:outline-none z-50'
              )}
            >
              <div className="!space-y-2">
                {menu.map((item: IMenuTypes, index: number) => (
                  <Fragment key={index}>
                    {isIMenuLink(item) && (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={item.to}
                            className={clsx(
                              active && 'bg-[#E2E6DF80]',
                              item.className,
                              rounded[item?.itemRound || 'none'],
                              'text-xs',
                              'inline text-left border border-transparent transition ease-in-out duration-150',
                              'group flex items-center w-100 !my-2 !mx-2 !px-1 !py-0.5 rounded-md',
                              {
                                'text-black': !item.disabled,
                                'text-gray-300 !cursor-not-allowed': item.disabled
                              }
                            )}
                          >
                            {item.icon}
                            {item.label}
                          </Link>
                        )}
                      </Menu.Item>
                    )}

                    {isIMenuAtag(item) && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={item.href}
                            target={item.target}
                            rel="noreferrer"
                            className={clsx(
                              active && 'bg-[#E2E6DF80]',
                              item.className,
                              rounded[item?.itemRound || 'none'],
                              'text-black text-xs !hover:bg-[#E2E6DF80]',
                              'group flex items-center w-full m-2 px-1 rounded-md'
                            )}
                          >
                            {item.icon}
                            {item.label}
                          </a>
                        )}
                      </Menu.Item>
                    )}

                    {isIMenuButton(item) && (
                      <Menu.Item>
                        {({ active }) => (
                          <Button
                            variant="is-link"
                            type={item.type}
                            onClick={item.onClick}
                            size="inline"
                            rounded={item?.itemRound || 'none'}
                            className="w-full"
                            classNameButton={clsx(
                              item.className,
                              'hover:bg-[#E2E6DF80] hover:no-underline',
                              '!text-black text-xs',
                              'group flex items-center w-full mx-2 mb-2 px-1 py-0.5 rounded-md'
                            )}
                          >
                            {item.icon}
                            {item.label}
                          </Button>
                        )}
                      </Menu.Item>
                    )}

                    {isMenuActions(item) && (
                      <Fragment>
                        <Menu.Item>
                          {({ active }) => (
                            <Button
                              variant="is-link"
                              type="button"
                              size="inline"
                              rounded={item?.itemRound || 'none'}
                              className="w-full"
                              classNameButton={clsx(
                                item.className,
                                'hover:bg-gray-200 hover:no-underline',
                                'text-black text-xs',
                                'group flex items-center w-full px-2 py-2 uppercase'
                              )}
                            >
                              {/* <ChevronDownIcon className="w-5 h-5" /> */}
                              {item.icon}
                              {item.label}
                            </Button>
                          )}
                        </Menu.Item>

                        {item.actions.map((action, index) => {
                          return (
                            isIMenuButton(action) && (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Button
                                    variant="is-link"
                                    type="button"
                                    onClick={action.onClick}
                                    size="inline"
                                    rounded={item?.itemRound || 'none'}
                                    className="w-full"
                                    classNameButton={clsx(
                                      item.className,
                                      'hover:bg-gray-200 hover:no-underline',
                                      'text-black text-xs',
                                      'group flex items-center w-full pl-8 pb-1'
                                    )}
                                    disabled={action.disabled}
                                  >
                                    {action.label}
                                  </Button>
                                )}
                              </Menu.Item>
                            )
                          );
                        })}
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      </Portal>
    </Menu>
  );
};

export default MenuAction;
